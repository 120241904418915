@use 'sass:map';

$brand-100: #003333;
$brand-75: #075a4d;
$brand-50: #32835f;
$brand-30: #68ac67;
$brand-accent: #aad46b;
$brand-complimentary: #f9f871; // this may be changed
$link: $brand-75;

$primary: $brand-100;
$secondary: $brand-30;
$link: $brand-75;

$border-radius: 0.8rem;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
@import 'node_modules/bootstrap/scss/bootstrap';

$brand-colors: (
  'primary': $brand-100,
  'secondary': $brand-30,
);

$extended-colors: map.merge($colors, $brand-colors);

:root {
  @each $name, $color in $extended-colors {
    --bs-#{$name}-light: #{rgba($color, 0.1)};
  }
}

body {
  font-family: 'Helvetica Neue', 'Inter', sans-serif;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

hr {
  color: #ccc;
}

@for $i from 1 through 6 {
  .h#{$i} {
    font-weight: 700;
  }
}

.btn.btn-secondary {
  color: white;
}

.hover-light {
  transition: background-color 200ms ease-in-out;

  &:hover:not(:disabled) {
    background-color: #eee !important;
  }
}

.hover-text-white {
  &:hover {
    color: white;
  }
}

.table thead th {
  border-bottom-width: 1px;
}

.cursor-pointer {
  cursor: pointer;
}

.border-bottom-light {
  border-bottom: 1px solid #eee;
}

.ad__cart-widget {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 16px;
}

.ad__cart-panel {
  width: 500px;
  height: 650px;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}

ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 10px;
  width: 2px;
  height: 100%;
  z-index: 400;
  margin-top: 7px;
}

ul.timeline > li {
  margin: 20px 0;
  padding-left: 10px;
}

ul.timeline > li:before {
  content: ' ';
  background: #ddd;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  left: 6px;
  width: 10px;
  height: 10px;
  z-index: 400;
  margin-top: 7px;
}

.whitespace-pre {
  white-space: pre-wrap;
}

.container {
  padding-left: 16px;
  padding-right: 16px;
}

.underline {
  text-decoration: underline;
}

.ratio-3x4 {
  --bs-aspect-ratio: calc(6 / 5 * 100%);
}

.ratio-square {
  --bs-aspect-ratio: calc(1 / 1 * 100%);
}

.bg-cover {
  background-size: cover;
  background-position: center;
}

table {
  * > th {
    border-bottom: solid 1px #ddd !important;
  }
}

.brand-logo {
  width: 40px;
  height: 40px;
}

.no-border-shadow {
  &:focus {
    box-shadow: none;
    border-color: #ced4da;
  }
}

.top-25 {
  top: 25% !important;
}

.filepond--wrapper {
  input {
    display: none;
  }
}

.foldable {
  max-height: 600px;
  opacity: 1;
  transition: max-height 300ms, opacity 300ms;
  pointer-events: all;

  &.collapsed {
    max-height: 0;
    opacity: 0;
    pointer-events: none;
  }
}

.section-icon {
  aspect-ratio: 1;
  padding: 0.8em;
  background-color: rgba($color: $secondary, $alpha: 0.15);
  color: $secondary;

  &.red {
    background-color: rgba($color: $red, $alpha: 0.15);
    color: $red;
  }

  &.purple {
    background-color: rgba($color: $purple, $alpha: 0.15);
    color: $purple;
  }

  &.yellow {
    background-color: rgba($color: $yellow, $alpha: 0.15);
    color: $yellow;
  }

  &.blue {
    background-color: rgba($color: $blue, $alpha: 0.15);
    color: $blue;
  }
}

.circular-progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
  border: none;
  border-radius: 50%;
  width: 3em;
  height: 3em;
  color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  background-color: transparent;
  font-size: 16px;
  overflow: hidden;

  &.small {
    width: 15px;
    height: 15px;
    font-size: 8pt;
  }
}

.circular-progress::-webkit-progress-bar {
  background-color: transparent;
}

/* Indeterminate */
.circular-progress:indeterminate {
  -webkit-mask-image: linear-gradient(transparent 50%, black 50%),
    linear-gradient(to right, transparent 50%, black 50%);
  mask-image: linear-gradient(transparent 50%, black 50%),
    linear-gradient(to right, transparent 50%, black 50%);
  animation: circular-progress 6s infinite cubic-bezier(0.3, 0.6, 1, 1);
}

:-ms-lang(x),
.circular-progress:indeterminate {
  animation: none;
}

.circular-progress:indeterminate::before,
.circular-progress:indeterminate::-webkit-progress-value {
  content: '';
  display: block;
  box-sizing: border-box;
  margin-bottom: 0.25em;
  border: solid 0.25em transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  width: 100% !important;
  height: 100%;
  background-color: transparent;
  animation: circular-progress-pseudo 0.75s infinite linear alternate;
}

.circular-progress:indeterminate::-moz-progress-bar {
  box-sizing: border-box;
  border: solid 0.25em transparent;
  border-top-color: currentColor;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: transparent;
  animation: circular-progress-pseudo 0.75s infinite linear alternate;
}

.circular-progress:indeterminate::-ms-fill {
  animation-name: -ms-ring;
}

@keyframes circular-progress {
  0% {
    transform: rotate(0deg);
  }
  12.5% {
    transform: rotate(180deg);
    animation-timing-function: linear;
  }
  25% {
    transform: rotate(630deg);
  }
  37.5% {
    transform: rotate(810deg);
    animation-timing-function: linear;
  }
  50% {
    transform: rotate(1260deg);
  }
  62.5% {
    transform: rotate(1440deg);
    animation-timing-function: linear;
  }
  75% {
    transform: rotate(1890deg);
  }
  87.5% {
    transform: rotate(2070deg);
    animation-timing-function: linear;
  }
  100% {
    transform: rotate(2520deg);
  }
}

@keyframes circular-progress-pseudo {
  0% {
    transform: rotate(-30deg);
  }
  29.4% {
    border-left-color: transparent;
  }
  29.41% {
    border-left-color: currentColor;
  }
  64.7% {
    border-bottom-color: transparent;
  }
  64.71% {
    border-bottom-color: currentColor;
  }
  100% {
    border-left-color: currentColor;
    border-bottom-color: currentColor;
    transform: rotate(225deg);
  }
}

// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .ratio-16x9-lg {
    --bs-aspect-ratio: calc(9 / 16 * 100%);
  }

  .foldable {
    opacity: 1;

    &.collapsed {
      max-height: 600px;
      opacity: 1;
      pointer-events: all;
    }
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
